import {CommonReconnectingWebSocket} from '@codesphere/stubs-common/lib/messaging/CommonReconnectingWebSocket';
import {Sequential} from '@codesphere/utils-common/lib/Sequential';
import {BidiStreamStub} from '../stub/BidiStreamStub';
import {WebSocketStreamStub} from './WebSocketStreamStub';

export class WebSocketBidiStreamStub<ClientMessageT, ServerMessageT>
    extends WebSocketStreamStub<ClientMessageT, ServerMessageT>
    implements BidiStreamStub<ClientMessageT, ServerMessageT> {

    public constructor(
        socket: CommonReconnectingWebSocket,
        endpointIdSequential: Sequential,
        method: string
    ) {
        super(socket, endpointIdSequential, method);
    }

    public send(message: ClientMessageT): void {
        super.send(message);
    }
}
