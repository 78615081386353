import imagesLoaded from 'imagesloaded';

export const init = (): void => {
    const imgLoad = imagesLoaded('body');
    imgLoad.on('always', function () {
        document.querySelectorAll('.loading').forEach(loading => {
            loading.classList.add('--hide');
        });
    });
};
