import {has} from './has';

/**
 * Throws if the provided object is either null or undefined.
 */
export const checkHas = <T> (x?: null | T, description: string = ''): T => {
    if (has(x)) {
        return x;
    }
    throw new TypeError(`Argument ${description} must not be null or undefined.`);
};

export const hasFieldOfType = (args: Record<string, any>, field: string, type: string): boolean => {
    return field in args && typeof args[field] === type;
};
