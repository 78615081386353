import {googleAnalyticsScript} from './googleAnalyticsScript';

export const enableGoogleAnalytics = (): void => {
    /**
     * We use eval here because the script that is being evaluated is fully controlled by us and is inaccessible for a
     * user.
     */
    /* eslint-disable no-eval */
    eval(googleAnalyticsScript);
    /* eslint-enable no-eval */
};
