import {
    errStatus, getOk, Reply, SerializedReply,
} from '@codesphere/reply-common/lib/Reply';
import {CommonReconnectingWebSocket} from '@codesphere/stubs-common/lib/messaging/CommonReconnectingWebSocket';
import {duration} from '@codesphere/utils-common/lib/datetime';
import {Exception} from '@codesphere/utils-common/lib/exceptions';
import {Status} from '@codesphere/utils-common/lib/log';
import {
    resolvablePromise, throwOnTimeout,
} from '@codesphere/utils-common/lib/promise';
import {Sequential} from '@codesphere/utils-common/lib/Sequential';

import {CLIENT_CONTEXT_METHOD, HttpContext} from '../HttpContext';
import {RpcOptions} from '../stub/RequestReplyStub';
import {WebSocketClientMessage} from './WebSocketClientMessage';
import {WebSocketServerMessage} from './WebSocketServerMessage';

export type MessageListener<T> = (message: T) => Promise<void>;
export type CompleteListener = (end: Reply) => Promise<void>;

export class InvalidJson extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class SocketClosed extends Exception {
    public constructor(
        public readonly socket: CommonReconnectingWebSocket,
        message: string,
    ) {
        super(message);
    }
}

// Note: be wary when using this. This class will likely evolve.
export class RpcException extends Exception {
    public static withMessage(message: string): RpcException {
        return new RpcException(message);
    }

    protected constructor(message: string) {
        super(message);
    }
}

export const parse = <ServerMessageT>(
    data: string
): WebSocketServerMessage<ServerMessageT> => {
    try {
        return JSON.parse(data);
    } catch (e) {
        throw new InvalidJson(`${e}`);
    }
};

/**
 * Send an rpc client message via WebSocket.
 */
export const send = <ArgsType = unknown>(
    socket: CommonReconnectingWebSocket,
    message: WebSocketClientMessage<ArgsType>,
): Reply => {
        let serialized: any;
        try {
            serialized = JSON.stringify(message);
        } catch (e) {
            return errStatus(
                `The message is not serializable (${e}).`);
        }
        if ([socket.CLOSING, socket.CLOSED].includes(socket.readyState)) {
            return errStatus('WebSocket is in a closing/closed state.');
        }
        try {
            socket.send(serialized);
        } catch (err) {
            return (err instanceof Error)
                ? Reply.createFromError(err)
                : Reply.errStatus(`${err}`);
        }

        return getOk();
    };

// DO NOT use outside of this package. This will likely go away.
export const makeRpc = async <Response, Request>(
    socket: CommonReconnectingWebSocket,
    method: string,
    id: number,
    args: Request,
    options?: Partial<RpcOptions>,
): Promise<Response> => {
    const result = resolvablePromise<Response>();

    // TODO(michal): do a proper fix and get rid of these listeners here
    const reject = (
        message: string
    ) => result.reject(new SocketClosed(socket, message));
    const reply = (data: string) => {
        try {
            const msg = parse<SerializedReply<Response>>(data);
            if (msg.endpointId !== id) {
                return;
            }
            const r = msg.reply;
            if (r.code === Status.Ok) {
                result.resolve(r.data);
            } else {
                result.reject(RpcException.withMessage(r.errMessage));
            }
        } catch (e) {
            console.warn(`failed to parse message: ${e}, data: ${data}`);
        }
    };

    socket.onClose(reject);
    socket.onMessage(reply);
    try {
        // TODO(michal): rename endpointId
        const status = send<Request>(socket, {
            method,
            endpointId: id,
            args,
        });
        if (status.notOk()) {
            throw RpcException.withMessage(
                `send failed (method: ${method}, id: ${id},`
                + ` args: ${JSON.stringify(args)}): ${JSON.stringify(status)}`);
        }
        const timeout = options?.timeout ?? duration({seconds: 5});
        return await throwOnTimeout({timeoutMs: timeout.asMilliseconds(), promise: result, label: method});
    } finally {
        socket.removeEventListener('message', reply);
        socket.removeEventListener('close', reject);
    }
};

export const setClientContext = <ContextT extends HttpContext>(
    socket: CommonReconnectingWebSocket,
    context: ContextT,
    ids: Sequential,
): Promise<void> => makeRpc(socket, CLIENT_CONTEXT_METHOD, ids.next(), context);
