import {inspect} from 'util';
import chalk from 'chalk';

import {InvalidArgument, InvalidError, Uninitialized} from './errors';
import {has} from './has';
import {Status} from './log';
import {isString} from './types';

/*
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !! Do not import this module directly, !!
 * !! instead use the logging module.     !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

export enum LogLevel {
    Debug = 10,
    Info = 20,
    Warning = 30,
    Error = 40,
}

export interface Logger {
    log: (entry: LogEntry) => void;
}

type Formatter = (entry: LogEntry) => string;

export abstract class BaseLogger implements Logger {
    public log(entry: LogEntry): void {
        this.doLog(entry);
    }

    protected abstract doLog(entry: LogEntry): void;
}

abstract class FormattingLogger extends BaseLogger {
    protected constructor(protected readonly format: Formatter) {
        super();
    }

    protected doLog(entry: LogEntry): void {
        this.logFunction(entry.level)(this.format(entry));
    }

    protected abstract logFunction(level: LogLevel): (msg: string) => void;
}

const formatMessage = (msg: unknown): string => {
    return !has(msg)
        ? ''
        : isString(msg)
            ? `${msg} `
            : `\n${inspect(msg, {depth: null})}\n`;
};

const formatSimply = (entry: LogEntry): string => {
    const err = entry.cause;
    const tag = entry.tags?.length
        ? `(${entry.tags.map(t => `#${t}`).join(', ')}) `
        : '';
    return `${tag}${formatMessage(entry.message)}${
        has(err) ? `(${err.name}) ${err.message}\n${err.stack}` : ''
    }`;
};

const formatWithDetails = (entry: LogEntry): string => {
    const level = LogLevel[entry.level].charAt(0);
    return `[${entry.time}] ${level}: ${formatSimply(entry)}`;
};


const levelColors = {
    [LogLevel.Debug]: chalk.gray,
    [LogLevel.Info]: chalk.cyan,
    [LogLevel.Warning]: chalk.yellow,
    [LogLevel.Error]: chalk.red,
};

const formatInColor = (format: Formatter): Formatter => {
    return (entry: LogEntry) => (
        levelColors[entry.level] ?? chalk.black
    )(format(entry));
};

/** This is a copy of the old logJson format. */
const formatAsJson = (entry: LogEntry) => {
    const m = entry.message;
    const cause = entry.cause;
    return JSON.stringify({
        date: new Date().toISOString(),
        stack: entry.cause?.stack,
        message: `${has(m) ? inspect(m, {depth: null}) : ''}${
            has(cause) ? ` (${cause.name}) ${cause.message}` : ''
        }`,
        code: entry.level <= LogLevel.Info ? Status.Ok : Status.Error,
        level: entry.level,
        tags: entry.tags,
    });
};


/**
 * Logs {@link LogEntry}s to the {@link console}.
 *
 * Don't use this directly, use {@link logD}, {@link logI}, {@link logW} or
 * {@link logE}.
 */
export class ConsoleLogger extends FormattingLogger {
    public static coloredSimple(): FormattingLogger {
        return new ConsoleLogger(formatInColor(formatSimply));
    }

    public static detailed(): FormattingLogger {
        return new ConsoleLogger(formatWithDetails);
    }

    protected logFunction(level: LogLevel): (msg: string) => void {
        switch (level) {
            case LogLevel.Debug:
                return console.debug;
            case LogLevel.Info:
                return console.info;
            case LogLevel.Warning:
                return console.warn;
            case LogLevel.Error:
                return console.error;
            default:
                throw new InvalidArgument(`Invalid log level: ${level}`);
        }
    }
}

/**
 * Similar to ConsoleLogger, but always logs using console.log().
 */
export class ConsoleLogLogger extends FormattingLogger {
    public static json(): FormattingLogger {
        return new ConsoleLogLogger(formatAsJson);
    }

    public constructor(format: Formatter) {
        super(format);
    }

    protected logFunction(level: LogLevel): (msg: string) => void {
        return console.log;
    }
}

/**
 * Forwards {@link LogEntry}s to added {@link Logger}s.
 *
 * Don't use this directly, use {@link logD}, {@link logI}, {@link logW} or
 * {@link logE}.
 */
export class ForwardingLogger extends BaseLogger {
    private loggers: Logger[] = [];

    public addLogger(logger: Logger): this {
        this.loggers.push(logger);
        return this;
    }

    protected doLog(entry: LogEntry): void {
        if (this.loggers.length === 0) {
            throw new Uninitialized(
                'No loggers added. Forgot to call `initLogging`?'
            );
        }
        for (const x of this.loggers) {
            try {
                x.log(entry);
            } catch (e) {
                console.error(e);
            }
        }
    }
}


export class LogEntry<T extends string = string> {
    public time = new Date().toISOString();

    private constructor(
        public readonly message: unknown,
        public readonly level: LogLevel,
        public readonly tags?: T[],
        public readonly cause?: Error,
    ) {}

    public static create = <T extends string>(
        level: LogLevel,
        message: unknown,
        options?: LogOptions<T>
    ): LogEntry<T> => {
        const cause = options?.cause;
        const t = options?.tag ?? [];
        const tags = Array.isArray(t) ? t : [t];
        if (message instanceof Error) {
            if (has(cause)) {
                throw new InvalidArgument('Called logger with two errors.');
            }
            return new LogEntry(undefined, level, tags, message);
        }
        const error = !has(cause) || cause instanceof Error
            ? cause
            : new InvalidError(cause);
        return new LogEntry(message, level, tags, error);
    };
}

export type LogOptions<T extends string> = {
    cause?: Error | unknown;
    tag?: T | T[];
};
