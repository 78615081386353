import {HttpStatusCode} from '@codesphere/http-common/lib/HttpStatusCode';
import {CommonReconnectingWebSocket} from '@codesphere/stubs-common/lib/messaging/CommonReconnectingWebSocket';
import {NotImplemented} from '@codesphere/utils-common/lib/errors';
import {execAll} from '@codesphere/utils-common/lib/execAll';
import {has} from '@codesphere/utils-common/lib/has';
import {Sequential} from '@codesphere/utils-common/lib/Sequential';
import {HttpRequestReplyStub} from '../http/HttpRequestReplyStub';
import {HttpContext} from '../HttpContext';
import {setClientContext} from '../websocket/utils';
import {WebSocketBidiStreamStub} from '../websocket/WebSocketBidiStreamStub';
import {WebSocketRequestReplyStub} from '../websocket/WebSocketRequestReplyStub';
import {WebSocketServerStreamStub} from '../websocket/WebSocketServerStreamStub';
import {BidiStreamStub} from './BidiStreamStub';
import {RequestReplyStub} from './RequestReplyStub';
import {ServerStreamStub} from './ServerStreamStub';

export interface Endpoint<ContextT extends HttpContext> {
    requestReplyStub: RequestReplyStub,
    newBidiStreamStub: <ClientMessageT, ServerMessageT>(method: string)
        => BidiStreamStub<ClientMessageT, ServerMessageT>,
    newServerStreamStub: <ArgsT, ServerMessageT>(method: string)
        => ServerStreamStub<ArgsT, ServerMessageT>,

    setClientContext(context: ContextT): Promise<void>;
    close(): void;
}

export type ListenerRemovalFunction = () => void;

export class WebSocketEndpoint<ContextT extends HttpContext>
implements Endpoint<ContextT> {

    public readonly requestReplyStub: RequestReplyStub;
    protected readonly socket: CommonReconnectingWebSocket;
    protected context: ContextT;
    protected readonly endpointIdSequential: Sequential;
    protected connectionListeners: Record<number, () => void> = {};
    private readonly connectionListenerIdSequential;

    public constructor(socket: CommonReconnectingWebSocket) {
        this.socket = socket;
        this.endpointIdSequential = new Sequential();
        this.connectionListenerIdSequential = new Sequential(0);
        this.context = {
            requestHeaders: {},
            httpStatusCode: HttpStatusCode.Ok,
            responseHeaders: {},
        } as ContextT;
        this.requestReplyStub = new WebSocketRequestReplyStub(
            this.socket, this.endpointIdSequential);
        this.socket.onClose(() => this.socket.resolveWhenConnected().then(
            async () => {
                if (has(this.context)) {
                    await setClientContext(
                        this.socket, this.context, this.endpointIdSequential);
                }
                execAll(Object.values(this.connectionListeners));
            }
        ));
    }

    public close(): void {
        this.socket.close();
    }

    public newBidiStreamStub<ClientMessageT, ServerMessageT>(method: string):
        BidiStreamStub<ClientMessageT, ServerMessageT> {
        return new WebSocketBidiStreamStub<ClientMessageT, ServerMessageT>(
            this.socket, this.endpointIdSequential, method);
    }

    public newServerStreamStub<ArgsT, ServerMessageT>(
        method: string
    ): ServerStreamStub<ArgsT, ServerMessageT> {
        return new WebSocketServerStreamStub<ArgsT, ServerMessageT>(
            this.socket, this.endpointIdSequential, method);
    }

    public setClientContext(context: ContextT): Promise<void> {
        this.context = context;
        return setClientContext(this.socket, context, this.endpointIdSequential);
    }

    public onEachConnection(listener: () => void): ListenerRemovalFunction {
        const listenerId = this.connectionListenerIdSequential.next();
        this.connectionListeners[listenerId] = listener;
        return () => {
            delete this.connectionListeners[listenerId];
        };
    }
}

export class HttpAndWebSocketEndpoint<ContextT extends HttpContext>
extends WebSocketEndpoint<ContextT> {

    public readonly requestReplyStub: HttpRequestReplyStub;

    public constructor(url: string, socket: CommonReconnectingWebSocket) {
        super(socket);
        this.requestReplyStub = new HttpRequestReplyStub<ContextT>(url);
    }

    public setClientContext(context: ContextT): Promise<void> {
        super.context = context;
        this.requestReplyStub.setClientContext(context);
        return setClientContext(this.socket, context, this.endpointIdSequential);
    }
}

export class HttpEndpoint<ContextT extends HttpContext>
implements Endpoint<ContextT> {

    public readonly requestReplyStub: HttpRequestReplyStub;

    public constructor(url: string) {
        this.requestReplyStub = new HttpRequestReplyStub<ContextT>(url);
    }

    public close(): void {}

    public newBidiStreamStub<ClientMessageT, ServerMessageT>(method: string):
        BidiStreamStub<ClientMessageT, ServerMessageT> {
        throw new NotImplemented();
    }

    public newServerStreamStub<ArgsT, ServerMessageT>(method: string):
        ServerStreamStub<ArgsT, ServerMessageT> {
        throw new NotImplemented();
    }

    public setClientContext(context: ContextT): Promise<void> {
        return this.requestReplyStub.setClientContext(context);
    }
}
