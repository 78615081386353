import {UserActivityEvent} from '../UserActivityEvent';

export const completeRegistration: UserActivityEvent<undefined> = {
    data: undefined,
    name: 'CompleteRegistration',
    durationMilliseconds: 0,
};

export const isCompleteRegistration = (event: UserActivityEvent<unknown>): event is typeof completeRegistration =>
    event.name === completeRegistration.name;
