import {Container, injectable} from 'inversify';
import {fromReplyRethrowing} from '@codesphere/reply-common/lib/compat';
import {HttpEndpoint} from '@codesphere/rpc-common/lib/stub/Endpoint';
import {HttpContext, toHttpContext} from '@codesphere/streamy-common/lib/extensions/typing';
import {rpc, ServiceInterface} from '@codesphere/streamy-common/lib/spec';
import {NotFound, Exception} from '@codesphere/utils-common/lib/exceptions';
import {serviceId} from '@codesphere/utils-common/lib/injectable';
import {
    toAny,
    toVoid,
} from '@codesphere/utils-common/lib/typing';
import {Creds, SessionId, toCreds, toSessionId} from './types';

export class InvalidSessionId extends NotFound {
    public constructor(public readonly sessionId: string) {
        super(`The sessionId: ${sessionId} is not valid, please sign in.`);
    }
}

export class SignoutFailed extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class TokenGenerationFailed extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export const sessionService = {
    name: 'Session',
    context: toHttpContext,
    methods: {
        genAccessToken: rpc({
            access: 'TODO',
            response: toCreds,
            request: toSessionId,
        }),
        signOut: rpc({
            access: 'TODO',
            response: toVoid,
            request: toAny,
        }),
    },
} as const;

/**
 * In contrast to all other Stubs we use from the browser
 * the stub only offers methods that need to be called over http.
 *
 * Usually websocket stubs are generated like this:
 * // eslint-disable-next-line @typescript-eslint/naming-convention
 * export const SessionStub = createStubClass(sessionService);
 * export type SessionStub = typeof SessionStub;
 */
@injectable()
export class SessionStub {
    public static ID = serviceId<SessionStub>('SessionStub');

    public constructor(private readonly endpoint: HttpEndpoint<HttpContext>) {}

    public static bindForInjection(c: Container, url: string): void {
        c.bind(SessionStub.ID).toConstantValue(
            new SessionStub(new HttpEndpoint(url)),
        );
    }

    /**
     * @throws InvalidSessionId, TokenGenerationFailed
     */
    public async genAccessToken(args: SessionId): Promise<Creds> {
        return toCreds(await fromReplyRethrowing(
            async () => await this.callEndpoint('genAccessToken', args),
            [InvalidSessionId, () => new InvalidSessionId(args.id ?? '')],
            () => new TokenGenerationFailed('Access token generation failed'),
        ));
    }

    /**
     * @throws SignoutFailed
     */
    public async signOut(): Promise<void> {
        return toVoid(await fromReplyRethrowing(
            async () => await this.callEndpoint('signOut', {}),
            (e) => new SignoutFailed(e.message),
        ));
    }

    private async callEndpoint(method: string, args: any): Promise<any> {
        return await this.endpoint.requestReplyStub.execute(method, args);
    }
}

export type SessionServiceApi = ServiceInterface<typeof sessionService>;
