import {Duration, toDuration} from './datetime';
import {logError} from './errors';
import {Exception, MultiException, TimedOut} from './exceptions';
import {logJson, Status} from './log';
import {logE} from './logging';

/**
 * Awaits the completion of all promises (using allSettled()).
 * @returns the resolved values and logs the reject reasons
 */
 export const allLogRejected = async <T>(promises: Promise<T>[]): Promise<T[]> => {
    const [succeeded, rejected] = await allSplit(promises);
    rejected.forEach(res => logE(res));
    return succeeded;
};

/**
 * Awaits the completion of all promises (using allSettled()).
 * @returns the resolved values and logs the reject reasons
 */
export const allJsonLogRejected = async <T>(promises: Promise<T>[]): Promise<T[]> => {
    const [succeeded, rejected] = await allSplit(promises);
    rejected.forEach(res => {
        if (res instanceof Error) {
            logE(res);
        } else {
            logJson(JSON.stringify(res), Status.Error);
        }
    });
    return succeeded;
};

/**
 * Awaits the completion of all promises (using allSettled()).
 * @returns the reject reason for all rejected promises
 */
 export const allRejected = async <T>(
     promises: Promise<T>[],
 ): Promise<Error[]> => {
    const results = await Promise.allSettled(promises);
    return results
        .filter(
            (res): res is PromiseRejectedResult => (res.status === 'rejected'))
        .map(res => res.reason);
};

/**
 * Awaits the completion of all promises (using allSettled()).
 * @returns the values of all resolved promises
 */
export const allResolved = async <T>(promises: Promise<T>[]): Promise<T[]> => {
    const results = await Promise.allSettled(promises);
    return results
        .filter((res): res is PromiseFulfilledResult<Awaited<T>> => (res.status === 'fulfilled'))
        .map(res => res.value);
};

/**
 * Awaits the completion of all promises (using allSettled()).
 * @returns [resolved, rejected] the reject reason and resolved values
 */
export const allSplit = async <T>(
    promises: Promise<T>[],
): Promise<[T[], Error[]]> => {
    const rejected = await allRejected(promises);
    const resolved = await allResolved(promises);
    return [resolved, rejected];
};

/**
 * Awaits the completion of all promises (using allSettled()).
 * @returns the resolved values
 * @throws {MultiException} the reject reasons
 */
 export const allThrowRejected = async <T>(
    promises: Promise<T>[]
): Promise<T[]> => {
    const [succeeded, rejected] = await allSplit(promises);
    if (rejected.length > 0) {
        throw new MultiException(rejected);
    }
    return succeeded;
};

/**
 * Promise that represents an interruptable operation:
 * - interrupt() can be used to interrupt the operation & reject the promise
 * - finish() can be used when the operation is completed & resolve the promise
 */
export interface InterruptablePromise extends Promise<void> {
    interrupt: () => void,
    finish: () => void,
}

export class Interrupted extends Exception {
    public constructor(message: string = '') {
        super(message);
    }
}

export const interruptablePromise = (
    promise: Promise<void>,
    interrupt: () => void = () => {},
    finish: () => void = () => {},
): InterruptablePromise => {
    const p = promise as InterruptablePromise;
    p.interrupt = interrupt;
    p.finish = finish;
    return p;
};

/**
 * Returns a promise that resolves after the given wait time.
 * The execution can be stopped before that timeout by either calling
 * - interrupt that will reject the promise
 * - finish which will resolve the promise
 */
export const interruptableWait = (
    waitMs: Duration | number
): InterruptablePromise => {
    const p = resolvablePromise<void>();
    const t = toDuration(waitMs);
    const tId = setTimeout(p.resolve, t.asMilliseconds());
    const w = p as unknown as InterruptablePromise;
    w.interrupt = () => {
        clearTimeout(tId);
        p.reject(new Interrupted(`before ${t.asSeconds()}s elapsed`));
    };
    w.finish = () => {
        clearTimeout(tId);
        p.resolve();
    };
    return w;
};

export interface ResolvablePromise<T> extends Promise<T> {
    resolve: (value: T) => void,
    reject: (error: Error) => void,
}

export const resolvablePromise = <T>(): ResolvablePromise<T> => {
    let resolve: (value: T) => void;
    let reject: (error: Error) => void;

    const promise = new Promise<T>((res, rej) => {
        resolve = res;
        reject = rej;
    }) as ResolvablePromise<T>;

    promise.resolve = resolve!;
    promise.reject = reject!;
    return promise;
};

export interface ThrowOnTimeoutArgs<T> {
    label?: string,
    timeoutMs: number | Duration;
    promise: Promise<T>;
    interrupt?: () => void;
}

/**
 * Waits until a promise is resolved or throws TimedOut if the timeout has been
 * reached before the promise settles.
 *
 * The interrupt function is called when the timeout has been reached.
 *
 * @param label included as part of the thrown TimedOut exception.
 * @param interrupt Function that is called when the timeout has been reached.
 *      Intended to be used to end/abort any ongoing operation, as appropriate.
 */
export const throwOnTimeout = async <T>(
    {label, timeoutMs, promise, interrupt}: ThrowOnTimeoutArgs<T>,
): Promise<T> => {
    const timeout = toDuration(timeoutMs);
    const w = interruptableWait(timeoutMs);
    try {
        return await Promise.race([
            promise,
            (async () => {
                try {
                    await w;
                } catch (e) {
                    // we were interrupted (promise finished)
                }
                // we always throw here to appease the type checker
                // (otherwise this function would have to return T)
                throw new TimedOut(
                    `throwOnTimeout(${label ?? ''})`, timeout.asSeconds());
            })(),
        ]);
    } catch (e) {
        if (interrupt) {
            logError(interrupt);
        }
        throw e;
    } finally {
        w.interrupt();
    }
};

/**
 * This is similar to throwOnTimeout(), but does not throw (reject), unless the
 * given promise rejects.
 *
 * @param p the promise to wait for.
 * @return TimedOut (the class reference itself), if the timeout elapses before
 *      p resolves, otherwise, the value resolved by p.
 */
export const waitAtMost = async<T>(
    p: Promise<T>, timeout: Duration,
): Promise<T | typeof TimedOut> => {
    const w = interruptableWait(timeout.asMilliseconds());
    try {
        return await Promise.race([p, (async () => {
            await w;
            return TimedOut;
        })()]);
    } finally {
        w.finish();
    }
};
