/**
 * Newer Chrome versions throw if we try to access the local storage.
 */
export class SafeLocalStorage implements Storage {

    private fakeStorage: Map<string, string> = new Map();

    public get length(): number {
        try {
            return localStorage.length;
        } catch (e) {
            return this.fakeStorage.size;
        }
    }

    public clear(): void {
        try {
            localStorage.clear();
        } catch (e) {
            this.fakeStorage.clear();
        }
    }

    public getItem(key: string): string | null {
        try {
            return localStorage.getItem(key);
        } catch (e) {
            return this.fakeStorage.get(key) ?? null;
        }
    }

    public key(index: number): string | null {
        try {
            return localStorage.key(index);
        } catch (e) {
            // Do not log
        }
        const keys = Array.from(this.fakeStorage.keys());
        if (index < 0 || index > keys.length) {
            return null;
        }
        return keys[index];
    }

    public removeItem(key: string): void {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            this.fakeStorage.delete(key);
        }
    }

    public setItem(key: string, value: string): void {
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            this.fakeStorage.set(key, value);
        }
    }
}

export const safeLocalStorage = new SafeLocalStorage();
