import dayjs from 'dayjs';
import * as dayjsDuration from 'dayjs/plugin/duration';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import {isNumber} from './types';

dayjs.extend(dayjsDuration.default);
dayjs.extend(relativeTime.default);

export const duration = dayjs.duration;
export type Duration = ReturnType<typeof duration>;

export const toDuration = (tMs: Duration | number): Duration => {
    return isNumber(tMs) ? duration({milliseconds: tMs}) : tMs;
};

/**
 * Returns a human-readable relative time from the given date until now.
 * e.g. relativeTimeFromNow(new Date('1999-01-01')) => "20 years ago"
 */
export const relativeTimeFromNow = (date: Date): string => {
    return dayjs(date).fromNow();
};

/**
 * @param unixTimestamp in seconds
 */
export const dateFromUnix = (unixTimestamp: number): Date => {
    return dayjs.unix(unixTimestamp).toDate();
};

/**
 * Checks if the given date has expired the given lifetime
 */
export const isExpired = (startDate: Date, lifetime: Duration): boolean =>
    dayjs(new Date()).diff(startDate) > lifetime.asMilliseconds();
