import {readOnly, toObject, toString, toUndefOr} from '@codesphere/utils-common/lib/typing';

export const toSessionId = toObject({
    id: toUndefOr(readOnly(toString)),
});
export type SessionId = ReturnType<typeof toSessionId>;

export const toCreds = toObject({
    sessionId: readOnly(toString),
    accessToken: readOnly(toString),
});
export type Creds = ReturnType<typeof toCreds>;

// TODO(tobias): rename this, maybe to toSessionId and rename the type above
export const toSessionString = readOnly(toString);
export type SessionString = ReturnType<typeof toSessionString>;
