export type Class<T> = Constructor<T>;
export type Constructor<T> = new (...args: any) => T;

export type AsyncFunc0<R> = () => Promise<R>;
export type AsyncFunc1<R, A1> = (arg1: A1) => Promise<R>;
export type AsyncFunc2<R, A1, A2> = (arg1: A1, arg2: A2) => Promise<R>;
export type AsyncFunc3<R, A1, A2, A3> = (
    arg1: A1, arg2: A2, arg3: A3,
) => Promise<R>;

export type Func0<R> = () => R;
export type Func1<R, A1> = (arg1: A1) => R;
export type Func2<R, A1, A2> = (arg1: A1, arg2: A2) => R;
export type Func3<R, A1, A2, A3> = (arg1: A1, arg2: A2, arg3: A3) => R;
export type FuncN<R, A extends unknown[]> = (...args: A) => R;

export type VoidFunction = Func0<void>;

export const isBoolean = (x: unknown): x is boolean => 'boolean' === typeof x;
export const isNumber = (x: unknown): x is number => 'number' === typeof x;
export const isObject = (x: unknown): x is object => 'object' === typeof x;
export const isString = (x: unknown): x is string => 'string' === typeof x;
export const isFunction = (x: unknown):
    // eslint-disable-next-line @typescript-eslint/ban-types
    x is Function => 'function' === typeof x;

export type NonUndef<T> = null | NonNullable<T>;

export type Maybe<T> = null | undefined | T;
export type NullOr<T> = null | T;
export type UndefOr<T> = undefined | T;


export type ReturnTypeTuple<T extends unknown[]> =
    T extends [FuncN<infer H, [unknown]>, ...infer R]
        ? [H, ...ReturnTypeTuple<R>]
        : [];

export type RealRequired<T> = {
    [P in keyof T]-?: Exclude<T[P], undefined>;
};

export type ReadOnly<T> = Readonly<T>;

// Taken from manually tested:
// {@Link https://stackoverflow.com/questions/41879327}
/**
 * To be used with properties in an object.
 */
export type DeepReadOnly<T> =
    T extends (infer R)[]
        ? ReadonlyArray<DeepReadOnly<R>>
        // eslint-disable-next-line @typescript-eslint/ban-types
        : T extends Function
            ? T
            : T extends object
                ? DeepReadOnlyObject<T>
                : T;

/**
 * To be used on an Object.
 */
export type DeepReadOnlyObject<T> = {
    readonly [P in keyof T]: DeepReadOnly<T[P]>;
};

// Taken from and manually tested:
// {@Link https://stackoverflow.com/questions/58210331}
// Deprecated but still valid:
// {@Link https://www.typescriptlang.org/docs/handbook/advanced-types.html#distributive-conditional-types}
/**
 * Filter out the functions properties names.
 */
export type NonFunctionPropertyNames<T> = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

/**
 * Filter out the functions properties.
 */
export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

export type Element<T extends Array<any>> = T extends Array<infer E> ? E : never;

// In practice objects always have string keys; so we should not use any other
// key type.
export type Dict<T> = Record<string, T>;
export type StringDict = Dict<string>;
