import {AuthHttpContext} from '@codesphere/auth-service-common/lib/AuthHttpContext';
import {Endpoint} from '@codesphere/rpc-common/lib/stub/Endpoint';
import {getCookie} from '@codesphere/ui-browser/lib/Cookie';
import {getUrlParams} from '@codesphere/ui-browser/lib/Router';
import {UserActivityMetadata} from '@codesphere/user-activity-common/lib/UserActivityMetadata';
import {
    UserActivityStubImpl,
} from '@codesphere/user-activity-common/lib/UserActivityStub';
import {safeLocalStorage} from '@codesphere/utils-browser/lib/SafeLocalStorage';
import {forEachProperty} from '@codesphere/utils-common/lib/forEachProperty';
import {has} from '@codesphere/utils-common/lib/has';
import {randomString} from '@codesphere/utils-common/lib/randomString';

const testAndSet = (key: string, value: string) => !safeLocalStorage.getItem(key) &&
    safeLocalStorage.setItem(key, value);

/**
 * If the Query-parameter "aId" exists: overwrite anonymousId with value of the parameter.
 * Used for A/B-testing to ensure that the anonymousId is the same for welcome.codesphere.com and codesphere.com
 */
const testAndSetAnonymousId = () => {
    const urlParams = new URLSearchParams(location.search);
    const anonymousId = urlParams.get('aId');

    has(anonymousId)
        ? safeLocalStorage.setItem('anonymousId', anonymousId)
        : testAndSet('anonymousId', randomString());
};

const get = (key: string): string | undefined => safeLocalStorage.getItem(key) ?? undefined;

export const setUserActivityMetadata = (): void => {
    testAndSetAnonymousId();
    testAndSet('referrer', document.referrer);
    forEachProperty(getUrlParams(location.href),
        (key, value) => safeLocalStorage.setItem(key as string, value));
};

export const getUserActivityMetadata = (): UserActivityMetadata => {
    const result: Partial<UserActivityMetadata> = {};
    testAndSetAnonymousId();
    result.anonymousId = get('anonymousId');

    const userIdEntry = get('userId');
    if (has(userIdEntry)) {
        result.userId = parseInt(userIdEntry);
    }
    const appIdEntry = get('appIdEntry');
    if (has(appIdEntry)) {
        result.appId = parseInt(appIdEntry);
    }
    result.userAgent = window.navigator.userAgent;
    result.utmSource = get('utm_source');
    result.utmMedium = get('utm_medium');
    result.utmCampaign = get('utm_campaign');
    result.utmTerm = get('utm_term');
    result.url = location.href;
    result.referrer = get('referrer');
    result.fbclid = get('fbclid');
    result.gclid = get('gclid');
    result.dclid = get('dclid');
    result.appId = parseInt(getCookie('currentApp'));
    return result as UserActivityMetadata;
};

/**
 * Stub for the UserActivity service which handles set and retrieval (LocalStorage) of values which are only
 * accessible to the client.
 */
export class UserActivityBrowserStub extends UserActivityStubImpl {
    /**
     * Sets user activity metadata from the browser environment to the local storage.
     */
    public constructor(endpoint: Endpoint<AuthHttpContext>) {
        super(endpoint, (() => {
            setUserActivityMetadata();
            return getUserActivityMetadata() ?? {};
        })());
    }
}
