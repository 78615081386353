import {injectable} from 'inversify';
import {Authenticatable, Authentication} from '@codesphere/auth-service-common/lib/Authentication';
import {HttpStatusCode} from '@codesphere/http-common/lib/HttpStatusCode';
import {Reply, ReplyPromise} from '@codesphere/reply-common/lib/Reply';
import {HttpContext} from '@codesphere/rpc-common/lib/HttpContext';
import {Endpoint} from '@codesphere/rpc-common/lib/stub/Endpoint';
import {completeRegistration} from './events/CompleteRegistration';
import {connectPayment} from './events/ConnectPayment';
import {InvoicePaymentFailed} from './events/InvoicePaymentFailed';
import {lead} from './events/Lead';
import {newsletter} from './events/Newsletter';
import {PageSession} from './events/PageSession';
import {pageView} from './events/PageView';
import {PaidInvoice} from './events/PaidInvoice';
import {Purchase} from './events/Purchase';
import {SignUpEvent} from './events/SignUpEvent';
import {UserActivity} from './UserActivity';
import {UserActivityEvent} from './UserActivityEvent';
import {UserActivityMetadata} from './UserActivityMetadata';

export enum UserActivityMethods {
    LogUserActivity = 'logUserActivity',
    SessionStream = 'SessionStream'
}

export type CanonicalEvent =
    typeof connectPayment
    | PaidInvoice
    | InvoicePaymentFailed
    | typeof lead
    | typeof pageView
    | Purchase
    | PageSession
    | SignUpEvent
    | typeof newsletter
    | typeof completeRegistration;

export interface UserActivityStub extends Authenticatable {
    logUserActivity(userActivity: CanonicalEvent): Promise<Reply>
    logCustomEvent(userActivity: UserActivityEvent<unknown>): Promise<Reply>
}

@injectable()
export class UserActivityStubImpl implements UserActivityStub {
    private readonly endpoint: Endpoint<HttpContext>;
    private readonly userActivityMetadata_?: UserActivityMetadata;

    public constructor(endpoint: Endpoint<HttpContext>,
                       userActivityMetadata: UserActivityMetadata) {
        this.endpoint = endpoint;
        this.userActivityMetadata_ = userActivityMetadata;
    }

    public async logUserActivity(userActivity: CanonicalEvent): ReplyPromise {
        return this.logCustomEvent(userActivity);
    }

    public async logCustomEvent(userActivity: UserActivityEvent<unknown>): ReplyPromise {
        return this.endpoint.requestReplyStub
            .execute<never, UserActivity<unknown>>(UserActivityMethods.LogUserActivity,
                Object.assign({}, userActivity, this.getUserActivityMetadata()));
    }

    public async authenticate(accessToken: Authentication['accessToken']): Promise<void> {
        const clientContext = {
            requestHeaders: {
                'Authorization': `Bearer ${accessToken}`,
            },
            responseHeaders: {},
            httpStatusCode: HttpStatusCode.Ok,
        };
        await this.endpoint.setClientContext(clientContext);
    }

    protected getUserActivityMetadata(): UserActivityMetadata | undefined {
        return this.userActivityMetadata_;
    }
}
