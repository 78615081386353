import {
    Reply, ReplyPromise, errStatus,
} from '@codesphere/reply-common/lib/Reply';
import {
    CommonReconnectingWebSocket,
} from '@codesphere/stubs-common/lib/messaging/CommonReconnectingWebSocket';

import {toError} from '@codesphere/utils-common/lib/errors';
import {Sequential} from '@codesphere/utils-common/lib/Sequential';

import {RequestReplyStub, RpcOptions} from '../stub/RequestReplyStub';
import {makeRpc} from './utils';

export class WebSocketRequestReplyStub implements RequestReplyStub {
    public constructor(
        private readonly socket: CommonReconnectingWebSocket,
        private readonly ids: Sequential,
    ) {}

    // TODO(michal): get rid of Reply; use exceptions
    public async execute<ReplyT, ArgsT>(
        method: string, args: ArgsT, options?: Partial<RpcOptions>,
    ): ReplyPromise<ReplyT> {
        try {
            return Reply.okReply(
                await makeRpc<ReplyT, ArgsT>(
                    this.socket, method, this.ids.next(), args, options));
        } catch (e) {
            return errStatus(toError(e));
        }
    }
}
