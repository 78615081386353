import {AlreadyInitialized, Uninitialized} from './errors';
import {
    ConsoleLogger, ConsoleLogLogger, ForwardingLogger,
    LogEntry, Logger, LogLevel, LogOptions,
} from './logger';

export {LogOptions} from './logger';
export type {ForwardingLogger} from './logger';


/**
 * Logs a message, Debug level
 */
export const logD = <T extends LogTag>(
    message: unknown, options?: LogOptions<T>,
): void =>
    LOGGER.log(LogEntry.create(LogLevel.Debug, message, options));
/**
 * Logs a message, Info level
 */
export const logI = <T extends LogTag>(
    message: unknown, options?: LogOptions<T>,
): void =>
    LOGGER.log(LogEntry.create(LogLevel.Info, message, options));
/**
 * Logs a message, Warning level
 */
export const logW = <T extends LogTag>(
    message: unknown, options?: LogOptions<T>,
): void =>
    LOGGER.log(LogEntry.create(LogLevel.Warning, message, options));
/**
 * Logs a message, Error level
 */
export const logE = <T extends LogTag>(
    message: unknown, options?: LogOptions<T>,
): void =>
    LOGGER.log(LogEntry.create(LogLevel.Error, message, options));

export enum LogEnv {
    Test,
    Local,
    Dev,
    Prod,
    Script,
}

/**
 * Logs can be filtered by these tags in our log aggregtion system.
 * Add new tags for specific topics (such as audit logs for e.g. fs operations)
 * or in rare cases for specific, hard to find bugs.
 */
export type LogTag = 'fs-del' | 'domain'; // | 'topic-x' | 'bug-y' | ...

/**
* Stack trace of where the logger was initialized.
*/
let initializedAt = '';

/**
* For use in logger tests.
*/
export const tearDownLogging = (): void => {
    LOGGER = uninitializedLogger();
    initializedAt = '';
};

const uninitializedLogger = (): Logger => {
    return {
        log: () => {
            throw new Uninitialized([
                'Logging not initialized. Possible causes:',
                ' - Forgot to call one of the initLogging functions.',
                ' - initLogging is imported from lib, but the logX functions'
                    + ' from src (or vv.).',
                '   (tests: compare the imports in the `jestEnv.setup.ts`'
                    + ' files with your logX imports.)',
                ' - Trying to log after tearDownLogging was called (e.g. after'
                    + ' test tear down).',
            ].join('\n'));
        },
    };
};

let LOGGER: Logger = uninitializedLogger();

const loggerForEnv = (env: LogEnv) => {
    switch (env) {
        case LogEnv.Local:
            return ConsoleLogger.coloredSimple();
        case LogEnv.Test:
            return ConsoleLogger.detailed();
        case LogEnv.Dev:
        case LogEnv.Prod:
            return ConsoleLogLogger.json();
        case LogEnv.Script:
            return ConsoleLogger.coloredSimple();
    }
};

/**
* Call this once at the very start.
*/
export const initLogging = (env: LogEnv): ForwardingLogger => {
    if (initializedAt) {
        throw new AlreadyInitialized(
            `Logging already initialized here:\n${initializedAt}\n`);
    }
    initializedAt = (Error().stack ?? '\nUnknown location')
        .split('\n')
        // Remove error message (empty) and the first line which just refers
        // to this file here (we are interested in the file that called this).
        .slice(2)
        // Note: There are two reasons for doing this.
        // - We want to differentiate this artificial stack trace form the stack
        //   trace of the Error that prints this (see `if` above).
        // - Jest splits an error into message and stack and prints a code
        //   block of the source of the error in between, but this refers to
        //   the actual stack trace and not this one here (i.e. it's worhless)
        //   in this special case. This makes jest not detect the trace as such
        //   which allows it to be printed above the code block.
        .map(x => x.replace(/^    at /, '      @ '))
        .join('\n');
    LOGGER = new ForwardingLogger().addLogger(loggerForEnv(env));
    return LOGGER as ForwardingLogger;
};

export const initLoggingFromConfig = (
    config: { logAsJson: string }
): ForwardingLogger => {
    return initLogging(config.logAsJson === 'true' ? LogEnv.Dev : LogEnv.Local);
};
