import {getCookie, setCookie} from '@codesphere/ui-browser/lib/Cookie';

export enum Cookie {
    Necessary = 'necessary',
    Performance = 'performance',
    Marketing = 'marketing',
}

// This is the maximum allowed expiry for a gdpr consent.
const GDPR_COOKIE_EXPIRY_DAYS = 180;

const isCookieSet = (cookie: Cookie) => getCookie(cookie) === 'true';

export const hasNecessaryCookie = (): boolean => {
    return isCookieSet(Cookie.Necessary);
};

export const hasPerformanceCookie = (): boolean => {
    return isCookieSet(Cookie.Performance);
};

export const hasMarketingCookie = (): boolean => {
    return isCookieSet(Cookie.Marketing);
};

export const setGdprConsentCookie = (
    cookie: Cookie,
    consent: boolean,
): void => setCookie(cookie, consent.toString(), GDPR_COOKIE_EXPIRY_DAYS);
