import {Exception} from './exceptions';
import {has} from './has';
import {logW} from './logging';
import {Class, isString, UndefOr} from './types';

/**
 * Base class for (typically) unrecoverable errors.
 */
export class RuntimeError extends Error {
    protected constructor(message: string) {
        super(message);
        new.target.prototype.name = this.constructor.name;
        Object.setPrototypeOf(this, new.target.prototype);
    }
}


export class AlreadyInitialized extends RuntimeError {
    public constructor(message: string) {
        super(message);
    }
}

export class NotImplemented extends RuntimeError {
    public constructor(message: string = 'Not Implemented') {
        super(message);
    }
}

export class InvalidArgument extends RuntimeError {
    public constructor(message: string) {
        super(message);
    }
}

export class InvalidError extends RuntimeError {
    public constructor(public readonly value: unknown) {
        super(`${value}`);
    }
}

export class InvalidOperation extends RuntimeError {
    public constructor(message: string) {
        super(message);
    }
}

export class InvalidState extends RuntimeError {
    public constructor(message: string) {
        super(message);
    }
}

export class MultiError extends RuntimeError {
    public constructor(public readonly errors: Error[]) {
        super(concatErrors(errors));
    }
}

export class NotRunningTest extends RuntimeError {
    public constructor(message: string) {
        super(message);
    }
}

export class Uninitialized extends RuntimeError {
    public constructor(message: string) {
        super(message);
    }
}

export class UnsupportedPlatform extends RuntimeError {
    public constructor(message: string) {
        super(message);
    }
}


export const concatErrors = (es: Error[]): string => {
    // The first line [of e.stack] is formatted as <error class name>: <error message>
    return es.map((e, i) => {
        const msg = has(e.stack) ? e.stack : `${e.name}: ${e.message}`;
        return `\nError ${i + 1} of ${es.length}: ${msg}`;
    }).join('\n');
};

/**
 * Ignores any error thrown by the passed function.
 */
export const ignoreError = <T>(func: () => T): UndefOr<T> => {
    try {
        return func();
    } catch (e) {
        return undefined;
    }
};

/**
 * Ignores any error thrown by the passed function.
 */
export const ignoreErrorAsync = async <T>(
    func: () => Promise<T>,
): Promise<UndefOr<T>> => {
    try {
        return await func();
    } catch (e) {
        return undefined;
    }
};

/**
 * Ignores any thrown error, logging it using logW.
 */
export const logError = <T>(
    func: () => T,
): UndefOr<T> => {
    try {
        return func();
    } catch (e) {
        logW(e);
        return undefined;
    }
};

/**
 * Ignores any thrown error, logging it using logW.
 */
export const logErrorAsync = async <T>(
    func: () => Promise<T>,
): Promise<UndefOr<T>> => {
    try {
        return await func();
    } catch (e) {
        logW(e);
        return undefined;
    }
};

/**
 * Checks the prototype chain for the given prototype name.
 */
const inheritsFrom = (obj: unknown, prototype: string): boolean => {
    let proto = Object.getPrototypeOf(obj);
    while (proto?.name && proto !== Object.prototype) {
        if (proto.name === prototype) {
            return true;
        }
        proto = Object.getPrototypeOf(proto);
    }
    return false;
};

export const toError = (e: unknown): Error => {
    // This is an ugly hack to work around this issue:
    // https://github.com/facebook/jest/issues/2549 (jest globals differ from
    // node globals)
    if (inheritsFrom(e, 'Error')) {
        return e as Error;
    }
    return new InvalidError(
        isString(e) ? e : JSON.stringify(e, null, 2)
    );
};

/**
 * Calls and returns func(), catching and returning any thrown Error.
 *
 * @throws InvalidError if the thrown error is not a subclass of Error.
 */
export const catchError = <T, E extends Error>(
    func: () => T, kind: Class<E>|ErrorConstructor = Error,
): T|E => {
    try {
        return func();
    } catch (err: unknown) {
        const e = toError(err);
        if (e instanceof kind) {
            return e as E;
        }
        throw e;
    }
};

/**
 * Calls and returns func(), awaiting the result, catching and returning any
 * thrown Error (filtered by kind).
 *
 * Caught non-Error types are first converted to an InvalidError using
 * `toError()` before being matched & returned or rethrown.
 *
 * @throws InvalidError or any thrown error if it's not an instance of kind.
 */
export const catchErrorAsync = async <T, E extends Error>(
    func: () => Promise<T>, kind: Class<E>|ErrorConstructor = Error,
): Promise<T|E> => {
    try {
        return await func();
    } catch (err: unknown) {
        const e = toError(err);
        if (e instanceof kind) {
            return e as E;
        }
        throw e;
    }
};


type ExceptionFilter = [
    typeof Exception | Class<Exception | RuntimeError>,
    (message: string) => Exception | RuntimeError,
]

const replaceException = (e: unknown, ...filter: ExceptionFilter[]): never => {
    filter.forEach(([trigger, replacement]) => {
        if (e instanceof trigger) {
            throw replacement(e.message);
        }
    });
    throw e;
};

/**
 * Wraps a synchronous function f with a filter that checks for given exceptions
 * and throws another more specific exception instead.
 */
export const rethrow = <ReturnT>(
    f: () => ReturnT,
    ...filter: ExceptionFilter[]
): ReturnT => {
    try {
        return f();
    } catch (e) {
        return replaceException(e, ...filter);
    }
};

/**
 * Async version of {@link rethrow}
 */
export const rethrowAsync = async <ReturnT>(
    f: () => Promise<ReturnT>,
    ...filter: ExceptionFilter[]
): Promise<ReturnT> => {
    try {
        return await f();
    } catch (e) {
        return replaceException(e, ...filter);
    }
};

/**
 * Calls func() and return true if an exception is thrown; false otherwise.
 */
export const threw = (
    func: () => unknown,
): boolean => {
    try {
        func();
        return false;
    } catch (e: unknown) {
        return true;
    }
};

/** @see threw(). */
export const threwAsync = async (
    func: () => Promise<unknown>,
): Promise<boolean> => {
    try {
        await func();
        return false;
    } catch (e: unknown) {
        return true;
    }
};

/**
 * @returns error or new InvalidError if error is not instance of Error
 */
export const withCurrentStack = (error: unknown): Error => {
    const e = toError(error);
    const currentStack = Error().stack?.split('\n').slice(1) ?? [];
    e.stack = [e.stack, 'Rethrown at: ', ...currentStack].join('\n');
    return e;
};
