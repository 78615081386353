import {Exception} from '@codesphere/utils-common/lib/exceptions';
import {Status} from '@codesphere/utils-common/lib/log';
import {Reply} from '../Reply';

/**
 * @deprecated Throw a more specific exception instead. If a function returns a
 *     ReplyException use {@see fromReplyRethrowing} to rethrow it.
 */
export class ReplyException extends Exception {
    public constructor(public readonly reply: Reply<never, Status.Error>) {
        super(reply.getErrorMessage());
    }
}
