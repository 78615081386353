import {UserActivityEvent} from '../UserActivityEvent';

export const newsletter: UserActivityEvent<undefined> = {
    data: undefined,
    name: 'Newsletter',
    durationMilliseconds: 0,
};

export const isNewsletter = (event: UserActivityEvent<unknown>): event is typeof newsletter =>
    event.name === newsletter.name;
