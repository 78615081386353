import {HttpStatusCode} from '@codesphere/http-common/lib/HttpStatusCode';

import {Duration, duration} from './datetime';
import {concatErrors} from './errors';
import {has} from './has';
import {logE} from './logging';
import {randomString} from './randomString';
import {isNumber} from './types';


/**
 * Base class for recoverable errors.
 */
export class Exception extends Error {
    protected constructor(message: string) {
        super(message);
        new.target.prototype.name = this.constructor.name;
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

export class UnknownException extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class Aborted extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class AlreadyExists extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class AlreadyRegistered extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class AlreadyUnregistered extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class WeakPassword extends Exception {
    public constructor() {
        super('Password needs to contain at least 8 characters, one lowercase character, ' +
            'one uppercase character, one number, and one special character.');
    }
}

export class NotFound extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class NotAuthorized extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class HttpException extends Exception {
    public constructor(public readonly code: HttpStatusCode, message: string) {
        super(message);
    }
}

export class TimedOut extends Exception {
    public constructor(label: string, timeoutS: Duration | number) {
        const t = isNumber(timeoutS) ? duration({seconds: timeoutS}) : timeoutS;
        super(`'${label}' timed out after ${t.asSeconds()}s.`);
    }
}

export class Closed extends Exception {
    public constructor(resource: string) {
        super(`${resource} is closed.`);
    }
}

export class MultiException extends Exception {
    public constructor(public readonly errors: Error[], summary?: string) {
        super(`${has(summary) ? `${summary}: ` : ''}${concatErrors(errors)}`);
    }
}

export class AlreadyCalled extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class AlreadyClosed extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class InvalidState extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class TooManyEvents extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

export class UnexpectedSymLink extends Exception {
    public constructor(message: string) {
        super(message);
    }
}

/**
 * Error to throw if you want to hide the real error from the user.
 * Imitating the default errStatus of the rpc server.
 * Always use throwInternalException instead of the constructor.
 */
export class InternalException extends Exception {
    /**
     * Unique id of this internal exception.
     * Used to match this error to the original error.
     */
    public readonly traceId: string;

    public constructor() {
        const id = randomString(8);
        super(`Internal server error ${id}.`);
        this.traceId = id;
    }
}

/**
 * Throws a save error which can be passed to the user.
 * Will log the real error with a random id thats also included in thrown error.
 * @param e unsave original error
 * @throws InternalException
 */
export const throwInternalException = (e: unknown): never => {
    const newE = new InternalException();
    logE(newE.traceId, {cause: e});
    throw newE;
};
