import {HttpStatusCode} from '@codesphere/http-common/lib/HttpStatusCode';
import {duration} from '../support/time';
import {
    toArray, toNumber, toNumericEnum, asObjectNoCopy, toOr, toRecord, toString,
    toUndefOr,
} from '../support/typing';


const toStringOrArray = toOr(toString, toArray(toString));
const toHeaders = toRecord(toUndefOr(toStringOrArray));

export const httpContext = {
    // TODO(michal): make these two header types the same
    requestHeaders: toHeaders,
    responseHeaders: toRecord(toOr(toStringOrArray, toNumber)),
    /**
     * This is intended to be set by a middleware, only if it is required by a
     * client of the api, to notify the
     * {@link RpcServer} that it should set the http status code in the response
     * (only if the response if send via http).
     */
    httpStatusCode: toNumericEnum('HttpStatusCode', HttpStatusCode),
};

export const toHttpContext = asObjectNoCopy(httpContext);

export type HttpContext = ReturnType<typeof toHttpContext>;
type TokenGen = {
    newToken: (payload: Record<string, unknown>, expiresInS: number) => string;
}

export const testHttpContext = (
    userId: number,
    tokenGen: TokenGen,
): HttpContext => {
    const user = {
        userId,
        email: 'test@example.com',
        authenticationMethod: 'password',
    };
    return {
        httpStatusCode: HttpStatusCode.Ok,
        requestHeaders: {
            authorization: `Bearer ${tokenGen.newToken(
                    user,
                    duration({seconds: 10}).asSeconds()
                )}`,
        },
        responseHeaders: {},
    };
};
