export const googleAnalyticsScript = `
const GA_ID = 'UA-174907530-1';
window.dataLayer = window.dataLayer || [];
window['ga-disable-' + GA_ID] = false;
function gtag() {
    window.dataLayer.push(arguments);
}
gtag('js', new Date());
gtag('config', GA_ID);
`;
