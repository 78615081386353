import {
    catchError,
    catchErrorAsync,
    InvalidArgument,
    RuntimeError,
} from '@codesphere/utils-common/lib/errors';
import {Exception} from '@codesphere/utils-common/lib/exceptions';
import {has} from '@codesphere/utils-common/lib/has';
import {Status} from '@codesphere/utils-common/lib/log';
import {checkHas} from '@codesphere/utils-common/lib/preconditions';
import {Class} from '@codesphere/utils-common/lib/types';

import {Reply} from './Reply';
import {ReplyException} from './utils/exceptions';

export const voidReply = Reply.getOk();

/**
 * @deprecated Use {@see fromReplyRethrowing} instead.
 */
export const fromReply = <T, S extends Status>(r: Reply<T, S>): T => {
    if (r.notOk()) {
        throw new ReplyException(r);
    }
    return r.getValue();
};

type Thrower = (re: ReplyException) => Exception | RuntimeError;
type Rethrower = [
    Exception | RuntimeError | Class<Exception | RuntimeError>,
    Thrower,
]

/**
 * Wraps an async function {@param fn} and triggers the last element of
 * {@param throwers} if reply is not ok.
 *
 * If for a thrown exception there is a matching rethrower {@param throwers},
 * a more specific exception will be thrown. Matching is done based on reply
 * error name.
 *
 * The last element must be of {@type Thrower} and always present. The rest of
 * elements must be of {@type Rethrower}.
 *
 * @example
 * const res = await fromReplyRethrowing(
 *     this.authService.signIn(email, password),
 *     [UnconfirmedEmail, (re) => new UnconfirmedEmail(re.message)],
 *     (re) => new SigninFailed(re.message),
 * );
 */
export const fromReplyRethrowing = async <T>(
    fn: () => Promise<Reply<T>>,
    // TODO(roman): Use tuple type destructuring once TS is bumped >4.2.
    ...throwers: (Rethrower | Thrower)[]
): Promise<T> => {
    const isThrower = (i: Rethrower | Thrower) => !Array.isArray(i);
    const rethrowers = throwers.slice(0, -1) as Rethrower[];
    const thrower = throwers.slice(-1)[0] as Thrower;
    if (!has(thrower)
        || !isThrower(thrower)
        || rethrowers.some(x => isThrower(x))
    ) {
        throw new InvalidArgument(
            'Expected an array of rethrowers with a thrower as a last argument',
        );
    }
    try {
        return fromReply(await fn());
    } catch (e) {
        const re = e as ReplyException;
        checkHas(re.reply, 'replyException.reply');
        const rethrower = rethrowers.find(
            ([error]) => (error.name === re.reply.getErrorName()),
        );
        if (has(rethrower)) {
            throw rethrower[1](re);
        }
        throw thrower(re);
    }
};

const valueOrErrorToReply = <T>(v: T|Error): Reply<T> => {
    return (v instanceof Error) ? Reply.createFromError(v) : Reply.okReply(v);
};

export const toReply = <T>(func: () => T): Reply<T> => {
    return valueOrErrorToReply(catchError(func));
};

export const toErrorReply = (err: Error): Reply<never, Status.Error> => {
    return Reply.createFromError(err);
};

export const toReplyAsync = async <T>(
    func: () => Promise<T>,
): Promise<Reply<T>> => {
    return valueOrErrorToReply(await catchErrorAsync(func));
};
