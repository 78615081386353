import Flickity from 'flickity';
import {getElementWidth} from './getElementSize';
import {screen} from './Screen';

export const bindFeaturedPostSliderMobile = (): void => {
    const wWindow = getElementWidth(document.body);
    const sliderSelector = '.scmainblog__featured';
    const sliders = document.querySelectorAll(sliderSelector);
    if (sliders.length) {
        if (wWindow < screen.tablet) {
            new Flickity(sliderSelector, {
                prevNextButtons: false,
                pageDots: true,
                cellAlign: 'center',
                dragThreshold: 0,
                wrapAround: false,
           });
        } else {
            for (let i = 0; i < sliders.length; i++) {
                const flickity = sliders[i].querySelectorAll('.flickity-slider');
                if (flickity.length) {
                    new Flickity(sliderSelector).destroy();
                    break;
                }
            }
        }
    }
};
