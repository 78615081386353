import {UserActivityEvent} from '../UserActivityEvent';

export const dateSelected: UserActivityEvent<undefined> = {
    name: 'CalendlyDateSelected',
    durationMilliseconds: 0,
    data: undefined,
};

export const eventScheduled: UserActivityEvent<undefined> = {
    name: 'CalendlyEventScheduled',
    durationMilliseconds: 0,
    data: undefined,
};
