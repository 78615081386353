import {safeLocalStorage} from '@codesphere/utils-browser/lib/SafeLocalStorage';

declare const window: any;

// Append ID to all internal links for Posthog identification across sub pages
export const appendAnonymousId = (): void => {
    document.querySelectorAll('a').forEach(link => link.addEventListener('click',
    (e) => {
        const destination = new URL(link.href);
        if (window.location.hostname !==
            destination.hostname &&
            destination.hostname.includes('codesphere.com')) {
            e.preventDefault();
            destination.searchParams.append('anonymousId', safeLocalStorage.getItem('anonymousId'));
            window.location = destination;
        }
    }));
};
