import {rethrow} from '../errors';
import {TypeConversionFailure} from '../typing';


export const toInteger = (x: unknown): number => {
    if (Number.isInteger(x)) {
        return x as number;
    }
    throw new TypeConversionFailure('Integer', x);
};

const toRestrictedInteger = (
    name: string, x: unknown, test: (n: number) => boolean,
): number => {
    const makeEx = () => new TypeConversionFailure(name, x);
    return rethrow(() => {
        if (test(toInteger(x))) {
            return x as number;
        }
        throw makeEx();
    }, [TypeConversionFailure, makeEx]);
};

export const toNonNegativeInteger = (x: unknown): number => {
    return toRestrictedInteger('NonNegativeInteger', x, (n) => n >= 0);
};

export const toPositiveInteger = (x: unknown): number => {
    return toRestrictedInteger('PositiveInteger', x, (n) => n > 0);
};
