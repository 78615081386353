import {has} from './has';

/**
 * Atomic, serializable and human readable status code for use in both internal services and API's.
 * Note that this is intended to be kept simple and should not be extended.
 */
export enum Status {
    Ok = 'Ok', Error = 'Error'
}

export enum LogTag {
    Default = 'Default',
    Notify = 'Notify'
}

/**
 * @deprecated Use {@link logI}
 * Formats and prints a message, a status code, and the current timestamp to the command line.
 */
export const log = (
    message: string,
    logAsJson: boolean,
    code: Status = Status.Ok,
    stack?: string,
    ...tags: LogTag[]
    ): string => {
    if (logAsJson) {
        return logJson(message, code, stack, ...tags);
    }
    return logPlain(message, code, stack, ...tags);
};

/**
 * @deprecated Use {@link logI}
 */
export const logPlain = (
    message: string,
    code: Status = Status.Ok,
    stack?: string,
    ...tags: LogTag[]
    ): string => {
    const tagsString = tags.map(tag => `, #${tag}`).join('');
    const logString: string = `[${code}, ${new Date().toISOString()}${tagsString}]: ${
        message
        }${has(stack) ? ('\n' + stack) : ''}`;
    // eslint-disable-next-line no-console
    console.log(logString);
    return logString;
};

/**
 * @deprecated Use {@link logI}
 */
export const logJson = (
    message: string,
    code: Status = Status.Ok,
    stack?: string,
    ...tags: LogTag[]
    ): string => {
    const logJson = JSON.stringify({date: new Date().toISOString(), stack, message, code, tags});
    // eslint-disable-next-line no-console
    console.log(logJson);
    return logJson;
};

