import {RpcOptions, ServerStream, Stream, Stub} from './api';
import {OmitNever, Resolve, TypeConverter} from './support/typing';


type AccessLevel = 'TODO' | 'internal' | 'public'

type MethodBaseSpec<Resp, Req> = {
    access: AccessLevel;
    response: TypeConverter<Resp>;
    request: TypeConverter<Req>;
}

export interface RpcSpec<Resp, Req> extends MethodBaseSpec<Resp, Req> {
    kind: 'rpc';
    defaultOptions?: Partial<RpcOptions>;
}

export interface StreamSpec<Resp, Req> extends MethodBaseSpec<Resp, Req> {
    kind: 'stream';
}

export const rpc = <Resp, Req>(
    spec: Omit<RpcSpec<Resp, Req>, 'kind'>,
): RpcSpec<Resp, Req> => ({...spec, kind: 'rpc'});

export const stream = <Resp, Req>(
    spec: MethodBaseSpec<Resp, Req>,
): StreamSpec<Resp, Req> => ({...spec, kind: 'stream'});


export const isRpcSpec = <R, Q>(
    s: RpcSpec<R, Q> | StreamSpec<R, Q>
): s is RpcSpec<R, Q> => {
    return 'rpc' === s.kind;
};

export const isStreamSpec = <R, Q>(
    s: RpcSpec<R, Q> | StreamSpec<R, Q>
): s is StreamSpec<R, Q> => {
    return 'stream' === s.kind;
};

export type MethodSpec<R, Q> = MethodBaseSpec<R, Q>
    & {kind: 'rpc' | 'stream', defaultOptions?: Partial<RpcOptions>};
export type MethodMap = Record<
    string,
    MethodSpec<unknown, unknown>
>;

export interface ServiceSpec<C, M extends MethodMap> {
    name: string;
    context: TypeConverter<C>;
    methods: M;
}

export type ServiceRpcSpecs<S extends ServiceSpec<any, any>>
    = S extends ServiceSpec<infer C, infer M>
    ? OmitNever<{
        [K in keyof M
        ]: M[K] extends RpcSpec<infer R, infer Q> ? M[K] : never;
    }> : never;

export type ServiceStreamSpecs<S extends ServiceSpec<any, any>>
    = S extends ServiceSpec<infer C, infer M>
    ? OmitNever<{
        [K in keyof M
        ]: M[K] extends StreamSpec<infer R, infer Q> ? M[K] : never;
    }> : never;


type ServiceRpcMethod<C, M extends RpcSpec<any, any>|StreamSpec<any, any>>
    = M extends RpcSpec<infer R, infer Q>
        ? (request: Q, context: C) => Promise<R>
        : never;

type ServiceStreamMethod<C, M extends RpcSpec<any, any>|StreamSpec<any, any>>
    = M extends StreamSpec<infer R, infer Q>
        ? (s: ServerStream<R, Q, C>) => Promise<void>
        : never;

export type ServiceRpcMethods<S extends ServiceSpec<any, any>>
    = S extends ServiceSpec<infer C, infer M>
    ? OmitNever<{
        [K in keyof M]: ServiceRpcMethod<C, M[K]>
    }> : never;

export type ServiceStreamMethods<S extends ServiceSpec<any, any>>
    = S extends ServiceSpec<infer C, infer M>
    ? OmitNever<{
        [K in keyof M]: ServiceStreamMethod<C, M[K]>
    }> : never;

export type ServiceInterface<S extends ServiceSpec<any, any>>
    = S extends ServiceSpec<infer C, infer M>
    ? { readonly SPEC: S } & ServiceRpcMethods<S> & ServiceStreamMethods<S>
    : never;


type StubMethod<C, M>
    = M extends RpcSpec<infer R, infer Q>
        ? (request: Q, options?: Partial<RpcOptions>) => Promise<R>
    : M extends StreamSpec<infer R, infer Q>
        ? () => Promise<Stream<R, Q>>
    : never;

type StubMethods<C, S> = {
    [K in keyof S]: StubMethod<C, S[K]>
}

export type StubInterface<S extends ServiceSpec<any, any>>
    = S extends ServiceSpec<infer C, infer M>
    ? Resolve<StubMethods<ReturnType<TypeConverter<C>>, M>>
    : never;


export class BaseStub {
    public constructor(protected readonly stub: Stub) {}
}

// TODO(michal): extract generic types out of these
export type StubWrapperClass<Base extends BaseStub> = new (stub: Stub) => Base;
export type StubWrapperSubClass<Base extends BaseStub, Sub>
    = new (stub: Stub) => Base & Sub


export const createStubSubClass = <
    S extends ServiceSpec<C, M>, C, M extends MethodMap,
    B extends BaseStub,
>(
    spec: S,
    base: StubWrapperClass<B>,
): StubWrapperSubClass<B, StubInterface<S>> => {
    class Klass extends base {}
    const p: any = Klass.prototype;

    for (const [name, m] of Object.entries(spec.methods)) {
        switch (m.kind) {
            case 'rpc':
                p[name] = async function (
                    this: B, req: any, opts?: Partial<RpcOptions>,
                ): Promise<any> {
                    const finalOpts = m.defaultOptions || opts
                        ? {...m.defaultOptions, ...opts}
                        : undefined;
                    return m.response(
                        await this.stub.call(name, req, finalOpts));
                };
                break;
            case 'stream':
                p[name] = function (this: B): any {
                    return this.stub.stream(name, m.response);
                };
                break;
        }
    }
    return Klass as StubWrapperSubClass<B, StubInterface<S>>;
};

export const createStubClass = <
    S extends ServiceSpec<C, M>, C, M extends MethodMap,
>(spec: S): StubWrapperSubClass<BaseStub, StubInterface<S>> => {
    return createStubSubClass(spec, BaseStub);
};
