import {threw} from './errors';
import {Exception} from './exceptions';
import {
    Func0, isBoolean, isNumber, isObject, isString, NonUndef,
    ReadOnly, ReturnTypeTuple,
} from './types';

export type Resolve<T> = T extends unknown ? {[K in keyof T]: T[K]} : never;

export type OmitNever<T> = {
    [K in keyof T as T[K] extends never ? never : K]: T[K]
}

type AllUndef<T> = {
    [K in keyof T]: undefined;
}

type OptionalOnly<T> = Resolve<OmitNever<{
    [K in keyof T]: AllUndef<Pick<T, K>> extends Pick<T, K> ? T[K] : never;
}>>;

type RequiredOnly<T> = Resolve<Omit<T, keyof OptionalOnly<T>>>;


export type TypeConverter<T> = (x: unknown) => T
type TypeSpec = Record<string, TypeConverter<unknown>>

type BaseType<T extends TypeSpec> = {
    [K in keyof T]: ReturnType<T[K]>;
};

export type Type<T extends TypeSpec> = Resolve<
    Partial<OptionalOnly<BaseType<T>>> & RequiredOnly<BaseType<T>>>


export class TypeConversionFailure extends Exception {
    public constructor(
        public readonly expectedType: string,
        public readonly value: unknown,
    ) {
        super(`expected a type of '${expectedType}', got: ${value}`);
    }
}

/**
 * Same as `!threw(() => converter(x))`, but helps typescript narrow down the
 * type of `x`.
 */
export const isOfType = <T>(
    x: unknown, converter: TypeConverter<T>
): x is T => {
    return !threw(() => converter(x));
};

/**
 * This should only be used on object fields (as passed to toObject).
 * It is the equivalent of annotating a field as read only.
 *
 * Example:
 *   interface {
 *     readonly foo: string;
 *   }
 * would be expressed as:
 *   toObject({
 *     foo: readOnly(toString),
 *   });
 *
 * Note: this is (currently) effectively, just an annotation.
 * TODO(michal): make this work
 */
export const readOnly = <T>(
    convert: TypeConverter<T>,
): TypeConverter<T> => convert;

export const toAny = (x: unknown): any => x;

export const toArray = <T>(
    convert: TypeConverter<T>,
): TypeConverter<Array<T>> => {
    return (x: unknown) => {
        if (Array.isArray(x)) {
            return x.map(convert);
        }
        throw new TypeConversionFailure('Array', x);
    };
};

export const toBoolean = (x: unknown): boolean => {
    if (isBoolean(x)) {
        return x;
    }
    throw new TypeConversionFailure('boolean', x);
};

export const toNull = (x: unknown): null => {
    if (x === null) {
        return x;
    }
    throw new TypeConversionFailure('null', x);
};

export const toNullOr = <T1, TN extends TypeConverter<any>[]>(
    convert1: TypeConverter<T1>, ...convertN: TN
): TypeConverter<null | T1 | ReturnType<TN[number]>> => {
    return toOr(toNull, convert1, ...convertN);
};

type NumericEnum<T extends string, V extends number> = {
    [K in T]: V;
};
type NumericEnumValue<
    T extends string, V extends number
> = NumericEnum<T, V>[T];

export const toNumericEnum = <T extends string, V extends number>(
    name: string, e: NumericEnum<T, V>,
): TypeConverter<NumericEnumValue<T, V>> => {
    return (x: unknown): NumericEnumValue<T, V> => {
        const v = isNumber(x) ? (e as any)[x] : undefined;
        if (v === undefined) {
            throw new TypeConversionFailure(`NumericEnum(${name})`, x);
        }
        return x as NumericEnumValue<T, V>;
    };
};

type StringEnum<T extends string, V extends string> = {
    [key in T]: V;
};
type StringEnumValue<
    T extends string, V extends string
> = StringEnum<T, V>[T];

export const toStringEnum = <T extends string, V extends string>(
    name: string, e: StringEnum<T, V>
): TypeConverter<StringEnumValue<T, V>> => {
    const revE = Object.fromEntries(Object.entries(e).map(([n, v]) => [v, n]));
    return (x: unknown): StringEnumValue<T, V> => {
        const v = isString(x) ? revE[x] : undefined;
        if (v === undefined) {
            throw new TypeConversionFailure(`StringEnum(${name})`, x);
        }
        return x as StringEnumValue<T, V>;
    };
};

export const toLiteral = <T extends boolean | number | string>(
    value: T
): TypeConverter<T> => {
    return (x: unknown): T => {
        if (x !== value) {
            throw new TypeConversionFailure('literal', x);
        }
        return value;
    };
};

export const toLiteralUnion = <
    T extends readonly B[], B = boolean | number | string
>(name: string, union: T): TypeConverter<typeof union[number]> => {
    return (x: unknown): typeof union[number] => {
        if (!union.includes(x as B)) {
            throw new TypeConversionFailure(`LiteralUnion(${name})`, x);
        }
        return x as typeof union[number];
    };
};

export const toNumber = (x: unknown): number => {
    if (isNumber(x)) {
        return x;
    }
    throw new TypeConversionFailure('number', x);
};

export const toRecord = <V> (
    toValue: TypeConverter<V>,
): TypeConverter<Record<string, V>> => {
    // TODO(michal): add nicer error messages
    return (x: unknown): Record<string, V> => {
        if (!isRecord(x)) {
            throw new TypeConversionFailure('record', x);
        }
        return Object.fromEntries<V>(
            Object.entries(x).map(([k, v]) => [toString(k), toValue(v)])
        );
    };
};

export const toString = (x: unknown): string => {
    if (isString(x)) {
        return x;
    }
    throw new TypeConversionFailure('string', x);
};

const isRecord = (x: unknown): x is Record<string, unknown> => isObject(x);

export const toObject = <S extends TypeSpec>(
    spec: S
): TypeConverter<Type<S>> => {
    return (obj: unknown) => {
        if (!isRecord(obj)) {
            // TODO(michal): could we be more specific here?
            throw new TypeConversionFailure('object', obj);
        }
        // TODO(michal): make the exception contain the (sub) field with the
        //               incorrect type
        return Object.fromEntries(
            Object.entries(spec).map(
                ([name, convert]) => [name, convert(obj[name])]
            ).filter(([name, value]) => value !== undefined)
        ) as unknown as Type<S>;
    };
};

/**
 * Prefer to use toObject and use this only,
 * when the object you are converting needs to be mutable.
 *
 * The behavior of this function is NOT the same as from toObject,
 * while fields known to spec are converted to their correct type
 * additional and undefined attributes are not removed from the object.
 */
export const asObjectNoCopy = <S extends TypeSpec>(
    spec: S
): TypeConverter<Type<S>> => {
    return (obj: unknown) => {
        if (!isRecord(obj)) {
            // TODO(michal): could we be more specific here?
            throw new TypeConversionFailure('object', obj);
        }
        Object.entries(spec).forEach(([name, convert]) => {
            const c = convert(obj[name]);
            if (c !== undefined || name in obj) {
                obj[name] = c;
            }
        });
        return obj as Type<S>;
    };
};

const toOr2 = <T1, T2>(
    convert1: TypeConverter<T1>, convert2: TypeConverter<T2>,
): TypeConverter<T1 | T2> => {
    return (x: unknown) => {
        // TODO(michal): use catchError()?
        let err1;
        try {
            return convert1(x);
        } catch (e) {
            if (!(e instanceof TypeConversionFailure)) {
                throw e;
            }
            err1 = e;
        }
        try {
            return convert2(x);
        } catch (e) {
            if (!(e instanceof TypeConversionFailure)) {
                throw e;
            }
            throw new TypeConversionFailure(
                `${err1.expectedType}|${e.expectedType}`, x);
        }
    };
};

export const toOr = <T1, T2, TN extends TypeConverter<any>[]>(
    convert1: TypeConverter<T1>, convert2: TypeConverter<T2>, ...convertN: TN
): TypeConverter<T1 | T2 | ReturnType<TN[number]>> => {
    const [convert3, ...rest] = convertN;
    return toOr2(
        convert1,
        undefined === convert3 ? convert2 : toOr(convert2, convert3, ...rest));
};

// Note: this is not for marking objects fields as 'readonly'. For that,
//       use readOnly().
export const toReadOnly = <T>(
    convert: TypeConverter<T>
): TypeConverter<ReadOnly<T>> => {
    return convert;
};

export const toTuple = <T1, T2, TN extends TypeConverter<any>[]>(
    conv1: TypeConverter<T1>,
    conv2: TypeConverter<T2>,
    ...convN: TN
): TypeConverter<[T1, T2, ...ReturnTypeTuple<TN>]> => {
    const converters = [conv1, conv2, ...convN];
    return (x: unknown) => {
        if (!Array.isArray(x)) {
            throw new TypeConversionFailure('Tuple', x);
        }
        return converters.map(
            (c, i) => c(x[i])) as [T1, T2, ...ReturnTypeTuple<TN>];
    };
};

export const toUndef = (x: unknown): undefined => {
    if (x === undefined) {
        return x;
    }
    throw new TypeConversionFailure('undefined', x);
};

export const toUndefOr = <T1, TN extends TypeConverter<any>[]>(
    convert1: TypeConverter<T1>, ...convertN: TN
): TypeConverter<undefined | T1 | ReturnType<TN[number]>> => {
    return toOr(toUndef, convert1, ...convertN);
};

/**
 * Does not allow undefined values; for that use toUndefOr(toUnknown).
 */
export const toUnknown = (x: unknown): NonUndef<unknown> => {
    if (x === undefined) {
        throw new TypeConversionFailure('unknown (excluding undefined)', x);
    }
    return x;
};

export const toVoid = (x: unknown): void => {};

export const toDate = (x: unknown): Date => {
    const date = new Date(x as any);
    if (isNaN(date.valueOf()) || x === null) {
        throw new TypeConversionFailure('Date', x);
    }
    return date;
};

/**
 * Type converter for an underlying T? type. Returns a default value in the case
 * of the value being undefined, otherwise uses the given type converter.
 */
export const withDefault = <T>(
    convert: TypeConverter<T>, makeDefault: Func0<T>,
): TypeConverter<T> => {
    return (x: unknown): T => (undefined === x) ? makeDefault() : convert(x);
};
