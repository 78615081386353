import fetch from 'cross-fetch';

import {Reply, ReplyPromise, errStatus} from '@codesphere/reply-common/lib/Reply';
import {duration} from '@codesphere/utils-common/lib/datetime';
import {toError} from '@codesphere/utils-common/lib/errors';
import {HttpException} from '@codesphere/utils-common/lib/exceptions';
import {has} from '@codesphere/utils-common/lib/has';
import {throwOnTimeout} from '@codesphere/utils-common/lib/promise';

import {HttpContext} from '../HttpContext';
import {RequestReplyStub, RpcOptions} from '../stub/RequestReplyStub';


export class HttpRequestReplyStub<Context extends HttpContext = HttpContext>
implements RequestReplyStub<Context> {

    private readonly url: string;
    private context: Context | undefined;

    public constructor(url: string) {
        this.url = url.endsWith('/') ? url : url + '/';
    }

    public async execute<ReplyType, ArgsType>(
        method: string, args: ArgsType, options?: Partial<RpcOptions>,
    ): ReplyPromise<ReplyType> {
        const abort = new AbortController();
        const config: RequestInit = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(args),
            signal: abort.signal,
        };

        if (has(this.context) && has(this.context.requestHeaders)) {
            config.headers = httpHeadersToFetchHeaders(this.context.requestHeaders);
        }

        try {
            const timeout = options?.timeout ?? duration({seconds: 30});
            return await throwOnTimeout({
                timeoutMs: timeout.asMilliseconds(),
                promise: (async () => {
                    // TODO(tim): replace with url builder
                    const res = await fetch(this.url + method, config);
                    try {
                        const b = await res.json();
                        return Reply.createFromSerializedReply<ReplyType>(b);
                    } catch (e) {
                        const msg = e instanceof Error
                            ? e.message
                            : res.statusText;
                        throw new HttpException(res.status, msg);
                    }
                })(),
                interrupt: () => abort.abort(),
                label: method,
            });
        } catch (e) {
            return errStatus(toError(e));
        }
    }

    public async setClientContext(context: Context): Promise<void> {
        this.context = context;
    }
}

const httpHeadersToFetchHeaders = (
    httpHeaders: Record<string, string | string[] | undefined>,
): Record<string, string> => {
    const fetchHeaders: Record<string, string> = {};
    for (const httpHeadersKey in httpHeaders) {
        const httpHeaderValue = httpHeaders[httpHeadersKey];

        if (!has(httpHeaderValue)) {
            continue;
        }

        const fetchHeaderValue = (
            Array.isArray(httpHeaderValue) && has(httpHeaderValue))
            ? httpHeaderValue.join(',')
            : httpHeaderValue;

        fetchHeaders[httpHeadersKey] = fetchHeaderValue;
    }
    return fetchHeaders;
};
