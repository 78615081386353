import {Container, interfaces} from 'inversify';
import {buildProviderModule, fluentProvide} from 'inversify-binding-decorators';
import {Constructor, Func1} from './types';
import ServiceIdentifier = interfaces.ServiceIdentifier;


export const buildContainer = (): Container => {
    const c = new Container({skipBaseClassChecks: true});
    c.load(buildProviderModule());
    return c;
};

export const serviceId = <T>(
    name: string
): ServiceIdentifier<T> => Symbol.for(name);

export type WithId<T> = Constructor<T> & { ID: ServiceIdentifier<T> };


const doProvide = <T>(
    provide: (id: ServiceIdentifier<T>) => Func1<void, Constructor<T>>,
    id?: ServiceIdentifier<T>
): Func1<void, Constructor<T>> | Func1<void, WithId<T>> => {
    return id ? provide(id) : (target: WithId<T>) => {
        provide(target.ID)(target);
    };
};

export function provide<T>(
    id: ServiceIdentifier<T>
): Func1<void, Constructor<T>>;
export function provide<T>(): Func1<void, WithId<T>>;
// eslint-disable-next-line func-style
export function provide<T>(
    id?: ServiceIdentifier<T>
): Func1<void, WithId<T>> | Func1<void, Constructor<T>> {
    return doProvide((id) => fluentProvide(id).done(), id);
}

export function provideSingleton<T>(
    id: ServiceIdentifier<T>
): Func1<void, Constructor<T>>;
export function provideSingleton<T>(): Func1<void, WithId<T>>;
// eslint-disable-next-line func-style
export function provideSingleton<T>(
    id?: ServiceIdentifier<T>
): Func1<void, WithId<T>> | Func1<void, Constructor<T>> {
    return doProvide((id) => fluentProvide(id).inSingletonScope().done(), id);
}
