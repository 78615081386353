import {UserActivityEvent} from '../UserActivityEvent';

const NAME = 'PageSession';

export interface PageSession extends UserActivityEvent {
    readonly name: typeof NAME;
}

export const isPageSession = (event: UserActivityEvent<unknown>): event is PageSession => event.name === NAME;

export const pageSession = (durationMilliseconds: number): PageSession => <PageSession>({
    name: NAME,
    durationMilliseconds,
});
